import NavigationBar from "../NavigationBar/NavigationBar";
import './LandingPage.css'


function LandingPage() {

    return (
        <div>
            {/* Navigation bar */}
            <NavigationBar pageId={'landing-page'} />

            <div className="landing-page-content">

                <img className="nellex-main-logo" alt="Nellex Brand Logo" src="/img/nellex-logo.png" />

                <div className="first-page">
                    <div className="column-container" id="upper">
                        <div className="column">
                            <p className="left-column-text">
                                Трикотажная фабрика NELLEX основана в 2012 году. Располагается в Российской Федерации, Карачаево-Черкесской республике, г. Черкесск. Фабрика является одним из ведущих производителей трикотажной промышленности России.
                            </p>
                        </div>
                        <div className="column">
                            <p className="right-column-text">
                                Nellex использует передовые инновационные технологии производства. На фабрике размещают свои заказы известные спортивные марки, бренды российского fashion-рынка, как премиум класса, так и ориентированные на масс-маркет. Компания сотрудничает с гипермаркетами, интернет магазинами, рекламными агентствами, а также с дизайнерскими брендами. Nellex – знак качества в трикотажной отрасли.
                            </p>
                        </div>
                    </div>
                    <div className="column-container" id="lower">
                        <div className="column">
                            <p className="left-column-text">
                                NELLEX knitwear factory was founded in 2012. It is located in Cherkessk, Karachay-Cherkess Republic, Russia. Nellex is one of the leading knitwear factories in the Russian Federation.
                            </p>
                        </div>
                        <div className="column">
                            <p className="right-column-text">
                                Nellex uses innovative manufacturing technologies. The factory works with sports brands, famous Russian brands, both high fashion and mass-market oriented. The company cooperates with hypermarkets, online stores, advertising agencies as well as designer brands. Nellex is a symbol of quality in knitwear industry.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="second-page">
                    <img className="nellex-logo-second-page" alt="Nellex Brand Logo" src="/img/nellex-logo.png" />
                    <div className="column-container" id="upper">
                        <div className="column">
                            <p className="right-column-text">
                                На фабрике представлена широкая линейка немецкого оборудования  для производства трикотажа,  марки Stoll разных классов от 1,5 до 14. Также имеются машины, совмещающие  в себе несколько классов, multi gauge  и knit&wear (бесшовные изделия).
                            </p>
                        </div>
                    </div>
                    <div className="column-container" id="lower">
                        <div className="column">
                            <p className="right-column-text" id="special">
                                The factory possesses wide range  of professional German “Stoll” knitting equipment that enables the production of knitwear of different gauges varying from 1.5 to 14.  There are also special multi-gauge and “knit&wear” machines allowing  to combine different gauges together as well as making seamless garments
                            </p>
                        </div>
                    </div>
                </div>

                <div className="third-page">

                </div>

                <div className="fourth-page">
                    <img className="nellex-logo-second-page" alt="Nellex Brand Logo" src="/img/nellex-logo.png" />
                    <div className="column-container" id="upper">
                        <div className="column">
                            <p className="right-column-text">
                                Лицензионное программное обеспечение М1plus позволяет реализовывать самые смелые дизайнерские решения
                            </p>
                        </div>
                    </div>
                    <div className="column-container" id="lower">
                        <div className="column">
                            <p className="right-column-text" id="special">
                                The licensed pattern software “M1 plus” allows to bring to life the most ambitious design ideas.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="image-holder">
                    <div className="image-item" id="first_photo"></div>
                    <div className="image-item" id="second_photo"></div>
                    <div className="image-item" id="third_photo"></div>
                    <div className="image-item" id="fourth_photo"></div>
                </div>

                <img className="nellex-logo-second-page" alt="Nellex Brand Logo" src="/img/nellex-logo.png" />

                <div className="fifth-page">
                    <div className="column-container" id="upper">
                        <div className="column">
                            <p className="left-column-text">
                                Фабрика использует полный цикл шелкотрафаретной печати.В паре с современными термопрессами реализована и запущена в массовое производство фольгирование изделий.Использование широкоформатных сублимационных принтеров Mimaki позволяет выпускать продукцию с нанесением полноцветного рисунка.
                            </p>
                        </div>
                    </div>
                    <div className="column-container" id="lower">
                        <div className="column">
                            <p className="right-column-text">
                                The factory uses the full cycle  of silkscreen printing. In conjunction with modern  thermopresses the factory has enabled the mass production  of foil printing technology.The use of Mimaki large format sublimation printers enables us to produce garments with a full color print.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="sixth-page">
                    <img className="nellex-logo-second-page" alt="Nellex Brand Logo" src="/img/nellex-logo.png" />
                    <div className="column-container" id="upper">
                        <div className="column">
                            <p className="right-column-text">
                                Производство качественной трикотажной продукции не обходится без стирки изделий. Поэтому использование немецкого стирального и сушильного оборудования Miele — одно из конкурентных преимуществ фабрики.
                            </p>
                        </div>
                    </div>
                    <div className="column-container" id="lower">
                        <div className="column">
                            <p className="right-column-text">
                                Washing is an indispensable stage of production process. So, the use of professional Germany  industrial washing and drying equipment by Miele  is one of the factory`s competitive advantages
                            </p>
                        </div>
                    </div>
                </div>

                <div className="image-holder">
                    <div className="image-item" id="fourth_photo"></div>
                    <div className="image-item" id="sixth_photo"></div>
                    <div className="image-item" id="seventh_photo"></div>
                </div>

                <div className="seventh-page">
                    <div className="column-container" id="upper">
                        <div className="column">
                            <p className="left-column-text-other">
                                Работа фабрики ориентирована как  на масс-маркет, так и на премиум класс. И в зависимости от желания заказчика используется пряжа ведущих турецких производителей, и пряжа класса премиум от известных фабрик Италии
                            </p>
                        </div>
                    </div>
                    <div className="column-container" id="lower">
                        <div className="column">
                            <p className="left-column-text-other">
                                In order to meet our  customers’ demands,  we use various types of yarn from leading Turkish  or Italian manufacturers
                            </p>
                        </div>
                    </div>
                </div>

                <div className="eighth-page">
                    <div className="column-container" id="upper">
                        <div className="column">
                            <p className="right-column-text">
                                Швейный парк фабрики оснащен полуавтоматическим и автоматическим оборудованием от ведущих производителей. Также имеется кеттельное оборудование разных классов. Швейный парк включает в себя более 100 единиц оборудования
                            </p>
                        </div>
                    </div>
                    <div className="column-container" id="lower">
                        <div className="column">
                            <p className="right-column-text">
                                The factory’s modern  sewing-machine park comprises semi-automatic as wen as fully automatic machines from leading vendors.There are also linking machines of different gauges.The sewing-machine park  includes over 100 pieces  of equipment
                            </p>
                        </div>
                    </div>
                </div>

                <img className="nellex-logo-second-page" alt="Nellex Brand Logo" src="/img/nellex-logo.png" />

                <div className="ninth-page">
                    <div className="column-container" id="upper">
                        <div className="column">
                            <p className="right-column-text">
                                Лицензия The Woolmark Company – гарантия лучшего продукта  на рынке.
                            </p>
                        </div>
                        <div className="column">
                            <p className="right-column-text">
                                The Woolmark Company  is a guarantee of the best quality on the market
                            </p>
                        </div>
                    </div>
                </div>

                <div className="image-holder">
                    <div className="image-item" id="ninth_photo"></div>
                    <div className="image-item" id="tenth_photo"></div>
                </div>

                <footer id="contacts_footer">
                    <div className="footer-div">
                        <div className="column-container" id="upper">
                            <div className="column">
                                <p className="left-column-text-other">
                                    Тел: <a href="tel:+78002222520">8 800 222 25 20</a>
                                    <br />
                                    Тел: <a href="tel:+78782270077">+7 (8782) 27 0077</a>
                                </p>
                                <p className="left-column-text-other">
                                    Адрес: Россия, Карачаево-Черкесская республика, г. Черкесск, пр. Ленина 332А
                                </p>
                                <p className="left-column-text-other">
                                    <a href="mailto:info@nellex.ru">info@nellex.ru</a>
                                    <br />
                                    <a href="https://nellex.su">www.nellex.su</a>
                                </p>
                            </div>
                            <div className="column">
                                <p className="left-column-text-other">
                                    Telephone number: <a href="tel:+78002222520">8 800 222 25 20</a>
                                    <br />
                                    Telephone number: <a href="tel:+78782270077">+7 (8782) 27 0077</a>
                                </p>
                                <p className="left-column-text-other">
                                    Address:  Russia, Karachay-Cherkess Republic, Cherkessk, Lenina av. 332А
                                </p>
                                <p className="left-column-text-other">
                                    Email: <a href="mailto:info@nellex.ru">info@nellex.ru</a>
                                    <br />
                                    Official website: <a href="https://nellex.su">www.nellex.su</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>

        </div>
    )
}

export default LandingPage;