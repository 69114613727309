import './App.css';
import {Route, Routes} from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import ContactsPage from "./components/ContactsPage/ContactsPage";

function App() {
    return (
        <div className="App">
          <Routes>
              <Route index element={<LandingPage />}></Route>
              <Route path={'/contacts'} element={<ContactsPage />}></Route>
          </Routes>
        </div>
    );
}

export default App;
