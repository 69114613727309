import NavigationBar from "../NavigationBar/NavigationBar";


function ContactsPage(){

    return(
        <>
            <NavigationBar pageId={'contacts-page'} />
            <div className={'contacts-page-content'}>
            </div>
        </>
    )
}

export default ContactsPage;