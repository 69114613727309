import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

function NavigationBar({ pageId }) {

    return (
        <div>
            <Navbar className="bg-body-tertiary"
                    style={{
                        position: 'fixed',
                        top: '0',
                        width: '100%',
                        zIndex: '100',
                    }}
            >
                <Container>
                    <Navbar.Brand href="#home">
                        <img
                            alt="" src="/img/nellex-icon.svg"
                            width="32" height="32"
                            className="d-inline-block align-top"
                        />
                        {' '}
                        <b>NELLEX</b>
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            {(pageId === 'landing-page') ? (
                                <Nav.Link href="#contacts_footer">Контакты</Nav.Link>
                            ) : (
                                <Nav.Link href="/">Главная страница</Nav.Link>
                            )}
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavigationBar;